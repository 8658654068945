import * as React from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import '../css/App.css';
import '../css/Markdown.css';

// @ts-ignore
const ReactMarkdown = require('react-markdown/with-html');

interface Props {
  languageI: number, // 0 = Finnish, 1 = English
}

interface State {
  markdown: string,
}

export default class TKrT extends React.Component<Props, State> {
  constructor(props :Props) {
    super(props);
    this.state = {
      markdown: '',
    };
  }

  async componentDidMount() {
    await this.updateMd(null);
  }

  async componentDidUpdate(prevProps :any) {
    await this.updateMd(prevProps);
  }

  async updateMd(prevProps :any) {
    if (!_.isEqual(this.props, prevProps)) { 
      let markdownUrl: any = "";
      switch (this.props.languageI) {
        case 0:
          markdownUrl = `${window.location.origin}/text/tkrt_fi.md`;
          break;
        case 1:
          markdownUrl = `${window.location.origin}/text/tkrt_en.md`;
      }
      let thisMarkdown: any = await axios.get(markdownUrl);
      this.setState({ markdown: thisMarkdown.data });
    }
  }
  
  render() {
    return (
      <div className="App-section">
        <div className="App-center">
            <ReactMarkdown
              source={this.state.markdown}
              escapeHtml={false}
            />
        </div>
      </div>
    );
  }
}
